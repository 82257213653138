.third-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #4b4f54;
  text-align: center;
}

h1 {
  color: #a3742c;
}

.product-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70%;
}

.carousel-button {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.products {
  display: flex;
  justify-content: center;
  width: 80%;
  overflow: hidden;
  position: relative;
  align-items: center;
  height: 100%;
}

.products.transition {
  transition: transform 0.3s ease-in-out;
}

.product {
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.product.selected {
  transform: scale(1.1);
  z-index: 10;
}

.product-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  transition: transform 0.3s;
  background-color: rgba(255, 255, 255, 0.425);
}

.product-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
