.first-section {
  flex: 1;
  height: 100vh;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .first-section {
    height: calc(100vh - 80px);
  }
}
