.product {
  position: relative;
  cursor: pointer;
  flex: 1; /* Allow flexbox to control the layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s, box-shadow 0.3s;
  gap: 30px;
}

.product:hover .product-name {
  opacity: 1;
}

.product:hover .product-circle {
  transform: scale(1.1);
}

.product:hover img {
  transform: scale(1.2);
}

.product-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image {
  position: absolute;
}

.product.selected .product-circle {
  transform: scale(1.3);
}

.product.selected img {
  transform: scale(1.5);
}

.product.selected .product-info {
  opacity: 1;
}

.notSelected {
  margin-top: 50px;
}

.product-name {
  opacity: 0;
  transition: opacity 0.3s;
  color: #a3742c;
}

.product-details {
  opacity: 0;
  transition: opacity 0.3s;
  color: #fbf6ed;
}

.product.selected .product-details {
  opacity: 1;
}

.product.selected .product-name {
  opacity: 1;
}
