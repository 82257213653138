.second-section-base {
  background-color: #fbf6ed;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.second-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .second-section {
    width: 80%;
  }
}

h1 {
  color: #a3742c;
  margin: 0;
}

h3 {
  font-weight: 400;
  letter-spacing: 7px;
  font-family: "Zesta-Italic", serif;
  display: block;
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
}

@media screen and (max-width: 1024px) {
  .image-logo {
    height: 50px;
  }

  h1 {
    font-size: 30px;
  }

  h3 {
    font-size: 15px;
  }
}
