.content-with-divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  /*gap: 20px;*/
}

.divider-item {
  width: 100px;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  content: "";
}

@media screen and (max-width: 1024px) {
  .content-with-divider {
    /*gap: 10px;*/
  }
}
